import { createWebHistory, createRouter } from "vue-router"
import SignIn from "@/components/auth/SignIn.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  let user = JSON.parse(localStorage.getItem("user"))
  if (user.access_token) isAuthenticated = true
  else isAuthenticated = false
  if (isAuthenticated) next()
  else next('/login')
}

const routes = [
  //login
  { path: "/", component: SignIn, meta: { title: 'Sign In' } },
  { path: "/login", component: SignIn, meta: { title: 'Sign In' } },
  { path: "/register", component: () => import("@/components/auth/Register.vue"), beforeEnter: authGuard, meta: { title: 'Register' } },
  { path: "/reset", component: () => import("@/components/auth/Reset.vue"), beforeEnter: authGuard, meta: { title: 'Reset credentials' } },
  { path: "/forget", component: () => import("@/components/auth/Forget.vue"), beforeEnter: authGuard, meta: { title: 'Forget Credentials' } },
  { path: "/all-transponders", component: () => import("@/components/Ship/TransponderLocation.vue"), beforeEnter: authGuard, meta: { title: 'Search Transponder Location' } },
  { path: "/landing-page", component: () => import("@/components/LandingPage.vue"), beforeEnter: authGuard, meta: { title: 'Landing Page' } },
  //warehouse one
  { path: "/inventory", component: () => import("@/components/warehouse one/Inventory.vue"), beforeEnter: authGuard, meta: { title: 'Received Transponders' , back: 'clients'} },
  { path: "/warehouse-one-transponders", component: () => import("@/components/warehouse one/AllWarehouse1Transponders.vue"), beforeEnter: authGuard, meta: { title: 'Received Transponders' , back: 'clients'} },
  { path: "/clients-inventory", component: () => import("@/components/warehouse one/ClientInventory.vue"), beforeEnter: authGuard, meta: { title: 'Received Transponders' , back: 'clients'} },
  { path: "/pre-orders", component: () => import("@/components/warehouse one/AgencyReceivedOrders.vue"), beforeEnter: authGuard, meta: { title: 'Received Transponders' , back: 'clients'} },
  { path: "/client-orders", component: () => import("@/components/warehouse one/ClientReceivedOrders.vue"), beforeEnter: authGuard, meta: { title: 'Received Transponders' , back: 'clients'} },
  { path: "/clients", component: () => import("@/components/warehouse one/Clients.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'landing-page'} },
  { path: "/client-agencies", component: () => import("@/components/warehouse one/ClientAgencies.vue"), beforeEnter: authGuard, meta: { title: 'Clients Agencies' , back: 'clients'} },
  { path: "/accounts", component: () => import("@/components/warehouse one/Accounts.vue"), beforeEnter: authGuard, meta: { title: 'Client-Accounts' , back: 'clients'} },
  { path: "/status", component: () => import("@/components/warehouse one/TransponderStatus.vue"), beforeEnter: authGuard, meta: { title: 'Transponder Status' , back: 'clients'} },
  { path: "/sub-clients", component: () => import("@/components/warehouse one/SubClients.vue"), beforeEnter: authGuard, meta: { title: 'SubClients' , back: 'clients'} },
  //warehouse two
  { path: "/clients-two", component: () => import("@/components/warehouse two/Clients.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'landing-page'} },
  { path: "/single-transponders", component: () => import("@/components/warehouse two/Inventory.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'clients-two'} },
  { path: "/openroads-transponders-inwarehouse", component: () => import("@/components/warehouse two/Openroadsnventory.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'clients-two'} },
  { path: "/openroads-warehouse", component: () => import("@/components/warehouse two/OpenroadsWarehouse.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'clients-two'} },
  { path: "/client-inventory", component: () => import("@/components/warehouse two/ClientInventory.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'clients-two'} }, 
  { path: "/all-openroads-transponders-inwarehouse", component: () => import("@/components/warehouse two/AllOpenroadsnventory.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'clients-two'} }, 
  { path: "/warehouse-two-transponders", component: () => import("@/components/warehouse two/AllWarehouse2Transponders.vue"), beforeEnter: authGuard, meta: { title: 'Clients' , back: 'clients-two'} }, 
  //open-orders
  // { path: "/open-orders", component: () => import("@/components/open-orders/Fulfillments.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/assign-transponders", component: () => import("@/components/open-orders/AssignTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/assign-batch", component: () => import("@/components/open-orders/AssignTranspondersBatch.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/crm-open-orders", component: () => import("@/components/open-orders/CRMOpenOrders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/openroads-open-orders", component: () => import("@/components/open-orders/OpenroadsOrders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/batch-request", component: () => import("@/components/open-orders/BatchRequests.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  //pick module
  { path: "/pick", component: () => import("@/components/Pick/PickTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  //pack module
  { path: "/pack", component: () => import("@/components/Pack/PackTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  //Ship Module
  { path: "/ship", component: () => import("@/components/Ship/ShipTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/all-shipped", component: () => import("@/components/Ship/AllShippedTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/openroads-shipped-transponders", component: () => import("@/components/Ship/AllOpenroadsShippedTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/shipped", component: () => import("@/components/Ship/ShippedTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/single-ship", component: () => import("@/components/Ship/SingleShipTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/single-shipped", component: () => import("@/components/Ship/SingleShippedTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  //RMA module
  { path: "/return-materials", component: () => import("@/components/RMA/RMADashboard.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  // virtual transponders
  { path: "/virtual-warehouse", component: () => import("@/components/virtualTransponders/warehouseTwo.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/virtual-shipped", component: () => import("@/components/virtualTransponders/ShippedTransponders.vue"), beforeEnter: authGuard, meta: { title: 'Client Transponder Fulfillments' , back: 'clients-two'} },
  { path: "/index.html", redirect: "/" },
] 

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = 'Innovative - ' + (toRoute.meta && toRoute.meta.title ? toRoute.meta.title : '')
  next()
})

export default router